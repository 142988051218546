import React, {FunctionComponent, useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {LocationChooser} from "./LocationChooser";
import {LatLng} from "leaflet";
import {InputSwitch} from "primereact/inputswitch";

export interface ISettings {
    location?: LatLng;
    overrideCurrentLocation?: boolean;
}

interface ISettingsDialogProps {
    settings?: ISettings;
    show?: boolean;
    onHide?: ()=>void;
    settingsSaved?: (settings:ISettings)=>void;
}

interface IFluidFormFieldProps {
    children: React.ReactNode;
    name:string;
    label?:string;
}
const FluidFormField:FunctionComponent<IFluidFormFieldProps> = (props) => {
    const childrenWithProps = React.Children.map(props.children, (child, index)=>{
        // @ts-ignore
        return React.cloneElement(child, {
            id: props.name
        });
    });
    return (<div className="p-field p-grid">
        <label htmlFor={props.name} className="">{props.label ? props.label : props.name}</label><br/>
        <div className="p-col-12 p-md-10">
            {childrenWithProps}
        </div>
    </div>);
}
type FluidFormProps = {
    children: React.ReactNode
};
const FluidForm:FunctionComponent<FluidFormProps> = (props) => {
    return (<div className="p-fluid">
        {props.children}
    </div>);
};

export const SettingsDialog = (props:ISettingsDialogProps) => {

    const [settings,setSettings] = useState(props.settings);
    useEffect(()=>{
        const serializedSettings = localStorage.getItem("settings");
        if(serializedSettings) {
            const theseSettings = JSON.parse(serializedSettings);
            if(theseSettings) {
                setSettings({
                    ...theseSettings
                });
            }
        }
    },[])

    const onSave = () => {
        if(settings) {
            const serializedSettings = JSON.stringify(settings);
            if (serializedSettings) {
                localStorage.setItem("settings", serializedSettings);
            }
            if (props.settingsSaved) {
                props.settingsSaved(settings);
            }
        }
    };

    const renderFooter = ()=>{
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => onHide()} className="p-button-text" />
                <Button
                    label="Save"
                    icon="pi pi-save"
                    onClick={(e)=>{
                        onSave();
                    }}
                    autoFocus />
            </div>
        );
    };

    const onHide = ()=>{
        if(props.onHide) {
            props.onHide();
        }
    };



    return (
        <Dialog header="Settings" visible={props.show}
                style={{textAlign: "left"}}
                footer={renderFooter()}
                onHide={() => onHide()}>
            <FluidForm>
                <FluidFormField name="override-current-location-switch" label="Override Current Location">
                    <InputSwitch
                        checked={!!settings?.overrideCurrentLocation}
                        onChange={e => {
                            setSettings({
                                ...settings,
                                overrideCurrentLocation: !!e.value
                            });
                        }}
                    />
                </FluidFormField>
                <FluidFormField name="override_current_location" label="Location">
                    <LocationChooser
                        location={settings?.location}
                        confirmed={settings?.overrideCurrentLocation}
                        disabled={!settings?.overrideCurrentLocation}
                        onChange={(e) => {
                            setSettings({
                                ...settings,
                                location: e
                            })
                        }}
                    />
                </FluidFormField>
            </FluidForm>
        </Dialog>
    );
};
