import React, {useEffect, useRef, useState} from "react";
import {DivIcon, Icon, LatLng, latLng, LatLngExpression} from "leaflet";
import {TileLayer, MapContainer, Marker, Popup, useMapEvents} from "react-leaflet";
import {OverlayPanel} from "primereact/overlaypanel";
import {Button} from "primereact/button";

import map_pin from './map-pin.svg';
import {Tag} from "primereact/tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faMapMarkedAlt} from "@fortawesome/pro-duotone-svg-icons";
/*
const map_pin_image = new Image();
let image_loaded = false;
map_pin_image.onload = ()=>{
    image_loaded = true;
};
map_pin_image.src = map_pin;

 */

/*
For data URI SVG support in Firefox & IE it's necessary to URI encode the string
& replace the '#' character with '%23'. `encodeURI()` won't do this which is
why `replace()` must be used on the string afterwards.
*/

const DefaultIcon = new Icon({
    iconUrl: map_pin,
    iconSize:     [64, 64],
    //shadowSize:   [50, 64],
    iconAnchor:   [31, 58],
    //shadowAnchor: [4, 62],
    popupAnchor:  [0, -50]
});

interface ILocationChooserProps {
    disabled?: boolean;
    location?: LatLngExpression;
    confirmed?: boolean;
    onChange?: (selectedVal:LatLng)=>void;
}

interface ILocationMarkerProps {
    location?: LatLngExpression;
    onChoose?:(latLng:LatLng)=>void;
}

const LocationMarker = (props:ILocationMarkerProps)=>{
    let position = null;
    if(props.location) {
        position = latLng(props.location);
    }
    const map = useMapEvents({
        click(e) {
            const pos = latLng(e.latlng);
            if(props.onChoose) {
                props.onChoose(pos);
            }
        },
    });

    return (<>
        {position === null ? (<></>) :
            (<Marker position={position} icon={DefaultIcon}>
            </Marker>)}
    </>);
};

export const LocationChooser = (props:ILocationChooserProps) => {

    const [locationIsConfirmed, setLocationIsConfirmed] = useState<boolean>(false);
    const [locationIsSet,setLocationIsSet] = useState<boolean>(!!props.location);
    const [selectedLocation,setSelectedLocation] = useState<LatLngExpression>(props.location ? props.location : {
        lat: 40.718286949635264,
        lng: -111.90236011724954
    });
    const [confirmedLocation, setConfirmedLocation] = useState<LatLngExpression>(props.location ? props.location : {
        lat: 40.718286949635264,
        lng: -111.90236011724954
    });
    useEffect(()=>{
        setLocationIsConfirmed(false);
    },[]);

    const overlayPanel = useRef<OverlayPanel>(null);
    const isMounted = useRef(false);


    useEffect(()=>{
        if(isMounted.current && locationIsConfirmed) {
            setConfirmedLocation(selectedLocation);
            overlayPanel.current?.hide();
        }
    },[selectedLocation, locationIsConfirmed]);

    useEffect(()=>{
        isMounted.current = true;
    },[]);

    const formatSelectedLocationTag = (location:LatLngExpression):string => {
        const loc = latLng(location);
        const format = (l:number):string => {
            return (Math.round(l * 1000) / 1000).toFixed(3);
        };
        return `${format(loc.lat)},${format(loc.lng)}`;
    };

    const formatSelectedLocation = (location:LatLngExpression):string => {
        const loc = latLng(location);
        const format = (l:number):string => {
            return (Math.round(l * 1_000_000) / 1_000_000).toFixed(6);
        }
        return `${format(loc.lat)}, ${format(loc.lng)}`;
    };

    let label;
    if(props.confirmed && props.location) {
        label = formatSelectedLocation(props.location);
    } else if(locationIsConfirmed && confirmedLocation) {
        label = formatSelectedLocation(confirmedLocation);
    } else {
        label = 'Select a Location';
    }

    return (<>
        <Button
            type="button"
            disabled={props.disabled}
            aria-disabled={props.disabled}
            icon="pi pi-search"
            label={label}
            onClick={(e)=>{
                overlayPanel.current?.toggle(e);
            }}
            aria-haspopup
            aria-controls="overlay_panel"
            className="select-location-button"
        />
        <OverlayPanel id="overlay_panel" ref={overlayPanel} showCloseIcon style={{width:'450px'}}>
            <MapContainer style={{height:"300px"}} center={selectedLocation} zoom={13} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker
                    location={selectedLocation}
                    onChoose={(e)=>{
                    setSelectedLocation(e);
                    setLocationIsSet(true);
                }} />
            </MapContainer>
            <div className="p-d-inline-flex p-mt-1">
              <Button
                  disabled={!locationIsSet}
                  label="Ok"
                  onClick={(e)=>{
                      if(props.onChange) {
                          const newLatLng = latLng(selectedLocation);
                          setConfirmedLocation(newLatLng);
                          setLocationIsConfirmed(true);
                          props.onChange(newLatLng);
                      }
                  }}/>
                <Tag
                    className="p-ml-1"
                    severity="info">
                    <FontAwesomeIcon  style={{float:"left"}} icon={faMapMarkedAlt}/>
                    <span style={{float:"left"}}>
                        {formatSelectedLocationTag(selectedLocation)}
                    </span>
                </Tag>
            </div>
        </OverlayPanel>
    </>);
};
