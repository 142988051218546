import React, {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import axios from "axios";
import {Dialog} from "primereact/dialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPaperPlane, faPortalExit, faSms, faWifi} from "@fortawesome/pro-duotone-svg-icons";
import {Button} from "react-bootstrap";
import {InputText} from "primereact/inputtext";

interface ISendTempPskModalProps {
    propertyAcronymGetter: ()=>string|undefined;
    ssidGetter: ()=>string|undefined;
    pskGetter: ()=>string|undefined;
    show?: boolean;
    onHide: () => void;
}


interface ISendCodeResponse {
    success: boolean;
    error: string|undefined;
}

export const SendTempPskModal = (props:ISendTempPskModalProps)=>{
    const [phoneInvalidMessage,setPhoneInvalidMessage] = useState<string|null>(null);
    const [emailInvalidMessage,setEmailInvalidMessage] = useState<string|null>(null);

    const [showEmailValidationErrors,setShowEmailValidationErrors] = useState<boolean>(false);
    const [showPhoneValidationErrors,setShowPhoneValidationErrors] = useState<boolean>(false);

    const [gotFirstEmailBlur,setGotFirstEmailBlur] = useState<boolean>(false);
    const [gotFirstPhoneBlur,setGotFirstPhoneBlur] = useState<boolean>(false);

    const [phone,setPhone] = useState<string>('');
    const [email,setEmail] = useState<string>('');

    const toast = useRef<Toast>(null);
    const sendMessage = ()=>{
        const property = props.propertyAcronymGetter();
        const ssid = props.ssidGetter();
        const psk = props.pskGetter();
        const sendEmail = (then:(didSend:boolean,error:any|undefined)=>void)=>{
            if(email && email !== '') {
                axios.post<ISendCodeResponse>(`https://bluer.im/send_email_tour_code/${property}`,{
                    email,
                    ssid,
                    psk
                })
                    .then(response=>{
                        if(response?.data?.success) {
                            then(true, undefined);
                        } else {
                            then(false, response.data.error || 'Unknown Error');
                        }
                    })
                    .catch(error=>{
                        then(false, error);
                    });
            } else {
                then(false, undefined);
            }
        };
        const sendSms = (then:(didSend:boolean,error:any|undefined)=>void)=>{
            if(phone && phone !== '') {
                axios.post<ISendCodeResponse>(`https://s360.pro/send_sms_tour_code/${property}`, {
                    phone,
                    ssid,
                    psk
                })
                    .then((response) => {
                        if(response?.data?.success) {
                            then(true, undefined);
                        } else {
                            then(false, response.data.error || 'Unknown Error');
                        }
                    })
                    .catch(error => {
                        then(false, error);
                    });
            } else {
                then(false, undefined);
            }
        };
        sendSms((didSendSms, smsError)=>{
            if(didSendSms) {
                toast.current?.show({severity: 'success', summary: 'Success', detail: `Successfully sent tour sms to ${phone}`, life: 3000});
            } else if(smsError) {
                toast.current?.show({severity: 'error', summary: 'Error', detail: (
                        <div>Error sending tour sms to '{phone}'<br/> {smsError.toString()}</div>
                    ), sticky: true});
            }
            sendEmail((didSendEmail, emailError)=>{
                if(didSendEmail) {
                    toast.current?.show({severity: 'success', summary: 'Success', detail: `Successfully sent tour email to '${email}'`, life: 3000});
                } else if(emailError) {
                    toast.current?.show({severity: 'error', summary: 'Error', detail: (
                            <div>Error sending tour email to '{email}'<br/> {emailError.toString()}</div>
                        ), sticky: true});
                }
            });
        });
    };

    const emailIsValid = (email:string):boolean => {
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return re.test(String(email).toLowerCase());
    };

    const phoneIsValid = (phone:string):boolean => {
        if(!phone) {
            return false;
        }
        phone = phone.replaceAll(/[^0-9]/g,'');
        const re = /^1?\d{10}$/;
        return re.test(phone);
    };

    useEffect(()=>{
        if(!emailInvalidMessage || emailInvalidMessage === '') {
            setShowEmailValidationErrors(false);
        } else {
            setShowEmailValidationErrors(true);
        }
        if(!phoneInvalidMessage || phoneInvalidMessage === '') {
            setShowPhoneValidationErrors(false);
        } else {
            setShowPhoneValidationErrors(true);
        }
    },[emailInvalidMessage,phoneInvalidMessage]);

    const [validForm,setValidForm] = useState(false);
    useEffect(()=>{
        let everythingValid = true;
        if(email
            && email !== '') {
            if(!emailIsValid(email)) {
                everythingValid = false;
            }
        }
        if(phone && phone !== '') {
            if(!phoneIsValid(phone)) {
                everythingValid = false;
            }
        }
        if((!phone || phone === '') && (!email || email === '')) {
            everythingValid = false;
        }
        setValidForm(everythingValid);
    },[email,phone]);

    useEffect(()=>{
        let invalidMessage:string|null = null;
        if(email && email !== '') {
            if(!emailIsValid(email) && gotFirstEmailBlur) {
                invalidMessage = "This doesn't look like a valid email";
            }
        }
        setEmailInvalidMessage(invalidMessage);
    },[email,gotFirstEmailBlur]);

    useEffect(()=>{
        let invalidMessage:string|null = null;
        if(phone && phone !== '') {
            if(!phoneIsValid(phone) && gotFirstPhoneBlur) {
                invalidMessage = "This doesn't look like a valid phone";
            }
        }
        setPhoneInvalidMessage(invalidMessage);
    },[phone,gotFirstPhoneBlur]);

    return (
        <>
            <Toast ref={toast}/>
            <Dialog
                onShow={()=>{
                    setEmail('');
                    setPhone('');
                    setShowEmailValidationErrors(false);
                    setShowPhoneValidationErrors(false);
                    setGotFirstEmailBlur(false);
                    setGotFirstPhoneBlur(false);
                }}
                visible={props.show}
                onHide={props.onHide}
                modal
                closable
                header={<>
                    <FontAwesomeIcon className="mr-1" icon={faWifi}/>
                    Share WiFi Details
                </>}
                footer={<>
                    <Button variant="secondary" onClick={props.onHide}>
                        <FontAwesomeIcon icon={faPortalExit}/>&nbsp;
                        Close
                    </Button>
                    <Button variant="greenrim" disabled={!validForm} onClick={sendMessage}>
                        <FontAwesomeIcon icon={faPaperPlane}/>&nbsp;
                        Send
                    </Button>
                </>}
            >
                <h4>Enter a number and/or email</h4>
                <div>
                    <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
                <FontAwesomeIcon className="mr-1" icon={faSms}/>
                Phone
            </span>
                        <InputText
                            className={`text-monospace ${showPhoneValidationErrors ? 'p-invalid' : ''}`}
                            value={phone}
                            onBlur={e=>setGotFirstPhoneBlur(true)}
                            onChange={e=>setPhone(e.currentTarget.value)}
                        />
                    </div>
                    <small id="phone-help" className={`${showPhoneValidationErrors ? 'p-invalid' : 'd-none'}`}>{phoneInvalidMessage}</small>

                    <div className="p-inputgroup mt-2">
            <span className="p-inputgroup-addon">
              <FontAwesomeIcon className="mr-1" icon={faEnvelope}/>
              Email
            </span>
                        <InputText
                            className={`text-monospace ${showEmailValidationErrors ? 'p-invalid' : ''}`}
                            value={email}
                            onBlur={e=>setGotFirstEmailBlur(true)}
                            onChange={e=>setEmail(e.currentTarget.value)}
                        />
                    </div>
                    <small id="email-help" className={`${showEmailValidationErrors ? 'p-invalid' : 'd-none'}`}>{emailInvalidMessage}</small>

                </div>
            </Dialog>
        </>
    );
};
