import React, { useEffect, useState } from 'react';
import {useGeolocation} from "react-use";
import './App.scss';
import {totp} from 'otplib';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {QrCodeComponent} from "./QrCodeComponent";
import {
  Button,
  Card,
  Col,
  FormControl,
  InputGroup,
  Row
} from "react-bootstrap";
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import words_json from "./words.json";
import axios, {AxiosResponse} from 'axios';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button as PrimeButton} from "primereact/button";
import {faWifi, faSms, faEnvelope, faKey, faPortalExit, faPaperPlane, faShareSquare} from "@fortawesome/pro-duotone-svg-icons";
import {getCenterOfBounds, getDistance, getPreciseDistance} from "geolib";
import {SendTempPskModal} from "./SendTempPskModal";
import {ProgressBar} from "primereact/progressbar";
import { ProgressSpinner } from 'primereact/progressspinner';
import {Dropdown} from 'primereact/dropdown';
import {ISettings, SettingsDialog} from "./SettingsDialog";
import {Message} from "primereact/message";
import {InputText} from "primereact/inputtext";

const words = words_json.words;


dayjs.extend(duration);
type GeoBounds = [/*lat*/number,/*lon*/number];

interface ISsidConfig {
  ssid: string;
  geoBounds:GeoBounds[];
  priority?: number;
}
interface IPropertyConfig {
  property_acronym:string;
  otp_key:string;
  ssids:ISsidConfig[];
}


interface INearestPropertyResponse {
  property_acronym: string;
  otp_key: string;
  ssid: string;
  priority?: number;
  location: IGeoLoc;
  distance: number;
}

interface IGeoLoc {
  latitude: number;
  longitude: number;
}

/*
const getMetaTag = (name:string):string|null=>{
  const metas = document.getElementsByTagName('meta');
  for(let i=0;i<metas.length;i++) {
    if(metas[i].getAttribute('name') === name) {
      return metas[i].getAttribute('content');
    }
  }
  return null;
};

 */

const step = 60 * 2; // Two minutes
totp.options = {
  step: step
};


interface IUsePosition {
  latitude?: number;
  longitude?: number;
  speed?: number;
  timestamp?: number;
  accuracy?: number;
  error?: string;
}


const App = ()=>{
  // let interval?:NodeJS.Timeout = undefined;

  const [propertyConfig,setPropertyConfig] = useState<IPropertyConfig|null>(null);

  const [isLoading,setIsLoading] = useState<boolean>(true);
  const [showSettings,setShowSettings] = useState<boolean>(false);
  const [settings,setSettings] = useState<ISettings>({});

  useEffect(()=>{
    let property_info_str = localStorage.getItem("property-info");
    if(property_info_str) {
      const possible_property_info = JSON.parse(property_info_str);
      if(possible_property_info.property_acronym && possible_property_info.otp_key && possible_property_info.ssids) {
        setPropertyConfig(possible_property_info);
      }
    }
    let settingsStr = localStorage.getItem("settings");
    if(settingsStr) {
      let settingsParsed = JSON.parse(settingsStr);
      if(settingsParsed) {
        setSettings(settingsParsed);
      }
    }
  },[]);

  const [timeRemainingPercent,setTimeRemainingPercent] = useState<number>(0);
  const [timeRemaining,setTimeRemaining] = useState<string>('');

  const [nearestSsidIdx, setNearestSsidIdx] = useState<number|undefined>(undefined);
  const [selectedSsidIdx, setSelectedSsidIdx] = useState<number|undefined>(undefined);
  const [ssidIdx,setSsidIdx] = useState<number|undefined>(undefined);
  useEffect(()=>{
    if(selectedSsidIdx !== undefined && selectedSsidIdx >= 0) {
      setSsidIdx(selectedSsidIdx);
    } else {
      setSsidIdx(nearestSsidIdx);
    }
  },[nearestSsidIdx,selectedSsidIdx]);

  const [modalShow,setModalShow] = useState<boolean>(false);

  const [psk,setPsk] = useState<string[]>([]);

  const [propertyAcronym,setPropertyAcronym] = useState<string>();
  const [ssids,setSsids] = useState<ISsidConfig[]>([{
    ssid: 'BlueRimOne',
    geoBounds: [[0,0]]
  }]);
  const [key,setKey] = useState<string>('12345');

  const ten_seconds = 10_000;

  const pos = useGeolocation({
    enableHighAccuracy: true,
    timeout: ten_seconds, // in milliseconds
    maximumAge: ten_seconds // in milliseconds
  });

  const one_mile_in_meters = 1609.34;


  const [lastGeneralGeoLoc,setLastGeneralGeoLoc] = useState<IGeoLoc>();
  const [lastGeoLoc,setLastGeoLoc] = useState<IGeoLoc>();
  useEffect(()=>{
    let thisLoc:undefined|{
      latitude:number|undefined,
      longitude:number|undefined
    } = undefined;
    if(settings.overrideCurrentLocation
        && settings.location
        && settings.location.lat
        && settings.location.lng) {
      thisLoc = {
        latitude: settings.location.lat,
        longitude: settings.location.lng
      };
    } else if(pos && !pos.error) {
      thisLoc = {
        latitude: pos.latitude ?? undefined,
        longitude: pos.longitude ?? undefined
      };
    }
    if(thisLoc) {
      // Get the most accurate pos
      // determine if the location has changed significantly since last check
      // if it has changed significantly, then update generalLatLon
      if(thisLoc.latitude && thisLoc.longitude) {
        if(!lastGeneralGeoLoc) {
          setLastGeneralGeoLoc({
            latitude: thisLoc.latitude,
            longitude: thisLoc.longitude
          });
        } else {
          const distanceFromLast = getDistance({
            lat: lastGeneralGeoLoc.latitude,
            lon: lastGeneralGeoLoc.longitude
          },{
            lat: thisLoc.latitude,
            lon: thisLoc.longitude
          });
          if(distanceFromLast > one_mile_in_meters) {
            setLastGeneralGeoLoc({latitude:thisLoc.latitude, longitude: thisLoc.longitude});
          }
        }

        // always set the latest geoloc
        if(lastGeoLoc?.latitude !== thisLoc.latitude || lastGeoLoc?.longitude !== thisLoc.longitude) {
          setLastGeoLoc({
            latitude: thisLoc.latitude,
            longitude: thisLoc.longitude
          });
        }
      }
    }
  },[pos, lastGeoLoc, lastGeneralGeoLoc, settings.location, settings.overrideCurrentLocation]);

  useEffect(()=>{
    if(lastGeoLoc) {
      const ssidsWithDistance = ssids.map((ssid, idx) => {
        const center = getCenterOfBounds(ssid.geoBounds);
        const dist = getPreciseDistance(lastGeoLoc, center, 0.1);
        return {
          ssid,
          idx: idx,
          distance: dist
        };
      }).sort((a,b)=>{
        return a.distance - b.distance;
      });
      const nearest = ssidsWithDistance[0];
      setNearestSsidIdx(nearest.idx);
    }
  },[lastGeoLoc, ssids]);

  useEffect(()=>{
    // When the location has changed greatly, then get the nearest property again
    if(lastGeneralGeoLoc?.latitude && lastGeneralGeoLoc?.longitude) {
      axios.get(`https://bluer.im/nearest_property/${lastGeneralGeoLoc.latitude}/${lastGeneralGeoLoc.longitude}`, {
        responseType:'json'
      }).then((response:AxiosResponse<INearestPropertyResponse>)=>{
        setPropertyAcronym(response.data.property_acronym)
      }).catch(err=>{
        console.error(`Could not get propertyName: ${err}`);
      });
    }
  },[lastGeneralGeoLoc]);

  useEffect(()=>{
    if(propertyAcronym) {
      console.log(`Got property acronym ${propertyAcronym}`);
      axios.get(`https://bluer.im/property_config/${propertyAcronym}`,{
        responseType:'json'
      }).then((result:AxiosResponse<IPropertyConfig>)=>{
        setPropertyConfig(result.data);
        localStorage.setItem('property-info', JSON.stringify(result.data));

        setSsids(result.data.ssids);
        setKey(result.data.otp_key);
        setIsLoading(false);
      }).catch(err=>{
        console.error(`Error getting result: ${err}`);
      });
    } else {
      console.log("Still loading property acronym...");
    }
  },[propertyAcronym]);

  const [token,setToken] = useState<string>('');
  useEffect(()=>{
    if(token === '') {
      return;
    }
    let parts = [
      parseInt(token.substr(0,3)),
      parseInt(token.substr(3, 3))
    ];
    setPsk([
      'tc',
      words[parts[0] % words.length].toLowerCase(),
      words[parts[1] % words.length].toLowerCase()
    ]);
  },[token]);

  const pad = (arg:number) =>{
    return arg < 10 ? `0${arg}` : `${arg}`;
  };

  const init = ()=>{

  };

  useEffect(()=>{
    const interval:NodeJS.Timeout = setInterval(()=>{
      let tRemaining = totp.timeRemaining();

      let newToken = totp.generate(key || '12345');
      if(newToken !== token) {
        setToken(newToken);
      }

      let duration = dayjs.duration({
        seconds: tRemaining
      });
      const totalSeconds = duration.asSeconds();
      const newPercent = 100 * (totalSeconds/ 120);
      if(newPercent !== timeRemainingPercent) {
        setTimeRemainingPercent(newPercent);
      }
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds - (60 * minutes);
      const newRemainingText = `${pad(minutes)}:${pad(seconds)}`;
      if(newRemainingText !== timeRemaining) {
        setTimeRemaining(newRemainingText);
      }
    }, 1000);
    return () => { clearInterval(interval); };
  },[key, token, timeRemaining, timeRemainingPercent]);

  const [ssidName,setSsidName] = useState<string|undefined>(undefined);
  useEffect(()=>{
    if(ssids && ssidIdx !== undefined && ssidIdx >= 0 && ssids.length > ssidIdx) {
      if(ssids[ssidIdx]?.ssid) {
        setSsidName(ssids[ssidIdx].ssid);
      }
    }
  },[ssids,ssidIdx]);

  return (
      <>
        <SendTempPskModal
            show={modalShow}
            onHide={()=>setModalShow(false)}
            propertyAcronymGetter={()=>{
              return propertyAcronym;
            }}
            ssidGetter={()=>{
              return ssidName;
            }}
            pskGetter={()=>{
              return psk != undefined && psk.length > 0 ? psk.join('') : undefined;
            }}
          />
          <SettingsDialog
            settings={settings}
            show={showSettings}
            onHide={()=>setShowSettings(false)}
            settingsSaved={(newSettings)=>{
              setSettings(newSettings);
              setShowSettings(false);
            }}
            />
        <header className="masthead mb-auto">
        </header>
        <main>
          <Card className="main-card">
              <Card.Body style={{"paddingBottom":"0"}}>
                <Card.Title>
                  <div>
                    <span>WiFi Tour Codes</span>

                  </div>
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                    Once used, the WiFi Password will remain valid for 2 hours.
                </Card.Subtitle>
                <Card.Body className="align-center">
                  {!settings.overrideCurrentLocation && pos.error ? (<div className="p-mb-1">
                    <Message severity="info" text="Could not get location. Check your settings or choose location from the config dialog." />
                  </div>) : null}
                  <ProgressBar
                      style={{"height":"0.5rem"}}
                      className="thin-progressbar"
                      mode={isLoading ? 'indeterminate' : 'determinate'}
                      color="#273646"
                      showValue={false}
                      value={timeRemainingPercent}/>
                  <span className="font-weight-light text-monospace" style={{"width":"10rem"}}>
                    <small>{timeRemaining}</small>
                  </span><br/><br/>

                  <div>
                    <div className="p-inputgroup mb-1">
                      <span className="p-inputgroup-addon">
                        <FontAwesomeIcon className="mr-1" icon={faWifi}/>
                        <span className="br-icon-label">WiFi</span>
                      </span>
                      {ssids.length > 1 ? (
                        <Dropdown
                            className="ssid-dropdown bluerim-ssid-dropdown"
                            optionValue="ssid"
                            options={ssids}
                            optionLabel="ssid"
                            value={ssidName}
                            onChange={(e)=>{
                              let idx = ssids.findIndex((ssidConfig)=>{
                                return ssidConfig.ssid === e.value;
                              });
                              if(idx !== undefined) {
                                setSelectedSsidIdx(idx);
                              }
                            }}/>
                    ):(<>
                          {isLoading ? (<>
                            <div className="spinner-input">
                            <ProgressSpinner className="bluerim-progress-spinner bluerim-progress-spinner-small" />
                            </div>
                          </>) : (<>
                            <InputText
                                readOnly
                                className="text-monospace font-weight-bold ssid-input"
                                value={ssidName}/>
                          </>)}
                    </>)}
                      <PrimeButton className="p-inputgroup-addon p-button-secondary" onClick={()=>setModalShow(true)}>
                        <FontAwesomeIcon icon={faShareSquare}/>
                      </PrimeButton>
                    </div>
                </div>
                <div>
                    <InputGroup className="mb-1">
                      <InputGroup.Text>
                        <FontAwesomeIcon className="mr-1" icon={faKey}/>
                        <span className="br-icon-label">Password</span>
                      </InputGroup.Text>
                      <div className="word-group text-center text-monospace">
                        {isLoading ? (<>
                          <ProgressSpinner className="bluerim-progress-spinner bluerim-progress-spinner-small" />
                        </>) : (<>
                        <span className="word-prefix"><strong>{psk[0]}</strong></span>
                        <span className="word1"><strong>{psk[1]}</strong></span>
                        <span className="word2"><strong>{psk[2]}</strong></span>
                        </>
                        )}
                      </div>
                    </InputGroup>
                </div>
                  {isLoading ? (<>
                    <ProgressSpinner className="bluerim-progress-spinner" />
                  </>) : (<>
                    <QrCodeComponent
                        className="br-qr-code"
                        ssid={ssidName || 'BlueRimOne'}
                        psk={psk}
                        width={600}
                        height={600}
                    />
                  </>)}

                <div>
                  <Col xs lg="12" className="pr-0">
                    <PrimeButton
                        icon="pi pi-refresh"
                        label="Update App"
                        className="p-button-sm float-left p-button-outlined p-button-secondary"
                        onClick={(e)=>{
                          window.location.reload();
                        }}
                    />
                    <PrimeButton
                        icon="pi pi-cog"
                        label="Settings"
                        className="p-button-sm float-right p-button-outlined p-button-secondary ms-3"
                        onClick={(e)=>{
                      setShowSettings(!showSettings);
                    }}/>
                  </Col>
                </div>
              </Card.Body>
            </Card.Body>
          </Card>
        </main>
        <footer className="mastfoot mt-auto">
          <div className="inner">
            <p>&copy; 2023 Smartaira</p>
          </div>
        </footer>
    </>
  );
};

export default App;
