import React, {createRef, useEffect, useRef, useState} from "react";
import qrcode from "qrcode-generator";
import {PATTERN_POSITION_TABLE} from "./pattern_position_table";
import Color from "color";


import SmartairaIcon from "@smartaira/smartaira-branding/src/images/icon_color.svg";
//import SmartairaHeaderImage from "@smartaira/smartaira-branding/dist/custom/main_logo.png";
//import BlueRimLogoSvg from "./logo.svg";

interface ILogos {
    image: HTMLImageElement,
    image_loaded: boolean,
    source: string,
    header_image: HTMLImageElement,
    header_image_loaded: boolean,
    header_image_source: string,
}

const useImage = (src:string) => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState<string | Event | null>(null);
    const imageRef = useRef(new Image());

    useEffect(() => {
        const image = imageRef.current;
        image.onload = () => {
            setLoaded(true);
        }
        image.onerror = (e) => setError(e);
        image.crossOrigin = "anonymous"; // handle potential CORS issues
        image.src = src;
        return () => {
            image.onload = null;
            image.onerror = null;
        };
    }, [src]);

    return { image: imageRef.current, loaded, error };
};


/*
const logos:{[val:string]:ILogos} = {
    "bluerim": {
        image: new Image(),
        image_loaded: false,
        source: BlueRimLogoSvg,
        header_image: new Image(),
        header_image_loaded: false,
        header_image_source: BlueRimLogoSvg,
    },
    "smartaira": {
        image: new Image(),
        image_loaded: false,
        source: SmartairaIcon,
        header_image: new Image(),
        header_image_loaded: false,
        header_image_source: SmartairaHeaderImage,
    },
};
["bluerim","smartaira"].forEach((brand)=>{
    logos[brand].image.onload = ()=>{
        logos[brand].image_loaded = true;
    };
    logos[brand].image.onerror = (error:any)=>{
        console.error(`Error loading image: ${error}`);
    }
    logos[brand].image.src = logos[brand].source;

    logos[brand].header_image.onload = ()=>{
        logos[brand].header_image_loaded = true;
    };
    logos[brand].header_image.onerror = (error:any)=>{
        console.error(`Error loading image: ${error}`);
    };
    logos[brand].header_image.src = logos[brand].header_image_source;
});
 */


export interface QrCodeProperties {
    className?: string;
    ssid:string;
    psk:string[];
    format?:string;
    // renderTo?:string;
    width?:number;
    height?:number;
    typeNumber?: TypeNumber;
    correctLevel?: ErrorCorrectionLevel;
    background?: Color;
    foreground?: Color;
    alignmentPattern?: Color;
    logoBackground?: Color;
    bullsEye?: Color;
    drawGrid?: boolean;
    roundEdges?: boolean;
}

export interface QrCodeState {
    ssid:string;
    psk:string[];
}


let typeNumber:TypeNumber = 0;
let correctLevel:ErrorCorrectionLevel = 'H';

interface QrCodeOptions {
    ssid: string;
    psk: string[];
    format: 'png' | 'pdf';
    // renderTo?: string;
    width: number;
    height: number;
    typeNumber: TypeNumber;
    correctLevel: ErrorCorrectionLevel;
    background: Color;
    foreground: Color;
    alignmentPattern: Color;
    logoBackground: Color;
    bullsEye: Color;
    drawGrid: boolean;
    roundEdges: boolean;
}

const branding = {
    smartaira_cyan: "#4dbad3",
    smartaira_orange: "#f89420",
    smartaira_violet: "#94327e",
    smartaira_midnight_blue: "#0f145b",
};
const smartaira_colors = {
    primary: branding.smartaira_midnight_blue,
    secondary: branding.smartaira_orange,
    logoBackground: "#ffffff"
};
const bluerim_colors = {
    primary: "#273646",
    secondary: "#77a43d",
    logoBackground: "#ffffff"
};

const defaultQrcodeOptions:QrCodeOptions = {
    ssid: 'BlueRim1',
    psk: ['bluerim1'],
    format: 'png',
    // render		: "canvas",
    width		: 256,
    height		: 256,
    typeNumber	: typeNumber,
    correctLevel	: correctLevel,
    background: Color.rgb("#f5f5f5"),
    foreground: Color.rgb(smartaira_colors.primary),
    alignmentPattern: Color.rgb(smartaira_colors.secondary),
    logoBackground: Color.rgb(smartaira_colors.logoBackground),
    bullsEye: Color.rgb(smartaira_colors.secondary),
    drawGrid: false,
    roundEdges: true
};



interface RadDefinition {
    tl:0|number;
    tr:0|number;
    bl:0|number;
    br:0|number;
}

/**
 * Draws a rounded rectangle using the current state of the canvas.
 * If you omit the last three params, it will draw a rectangle
 * outline with a 5 pixel border radius
 * @param {CanvasRenderingContext2D} ctx
 * @param {Number} x The top left x coordinate
 * @param {Number} y The top left y coordinate
 * @param {Number} width The width of the rectangle
 * @param {Number} height The height of the rectangle
 * @param {Number} [radius = 5] The corner radius; It can also be an object
 *                 to specify different radii for corners
 * @param {Number} [radius.tl = 0] Top left
 * @param {Number} [radius.tr = 0] Top right
 * @param {Number} [radius.br = 0] Bottom right
 * @param {Number} [radius.bl = 0] Bottom left
 * @param {Boolean} [fill = false] Whether to fill the rectangle.
 * @param {Boolean} [stroke = true] Whether to stroke the rectangle.
 */
function roundRect(ctx:CanvasRenderingContext2D, x:number, y:number, width:number, height:number, radius:number|RadDefinition, fill:boolean = false, stroke:boolean = true) {
    if (typeof radius === 'number') {
        radius = {tl: radius, tr: radius, br: radius, bl: radius};
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
        ctx.fill();
    }
    if (stroke) {
        ctx.stroke();
    }

}

function escape_string(str:string):string {
    let to_escape = /^[\\;,:"]$/;
    //var hex_only = /^[0-9a-f]+$/i;
    let output = "";
    for (let i=0; i<str.length; i++) {
        if(to_escape.test(str[i])) {
            output += '\\';
        }
        output += str[i];
    }
    //if (hex_only.test(output)) {
    //    output = '"'+output+'"';
    //}
    return output;
}

const draw = (canvas:HTMLCanvasElement|null, props:QrCodeProperties, logo:HTMLImageElement) => {
    if(!canvas) {
        return;
    }
    const image_brand = "smartaira";
    const options = {
        ...defaultQrcodeOptions,
        ...props,
    };
    let qrstring = 'WIFI:';
    qrstring += 'T:WPA;';
    qrstring += 'S:' + escape_string(options.ssid) + ';';
    qrstring += 'P:' + escape_string(options.psk.join('')) + ';';
    qrstring += ';';

    let qrc = qrcode(options.typeNumber,
        options.correctLevel);
    qrc.addData(qrstring);
    qrc.make();

    const qrtype = (qrc.getModuleCount() - 17) / 4;
    const pos = PATTERN_POSITION_TABLE[qrtype - 1];
    const pattern_pos_map: Map<string, boolean> = new Map<string, boolean>();
    for (let i = 0; i < pos.length; i += 1) {
        for (let j = 0; j < pos.length; j += 1) {
            let row = pos[i];
            let col = pos[j];
            if (row === 6 && col === 6) {
            } else if (row === qrc.getModuleCount() - 7 && col === 6) {
            } else if (row === 6 && col === qrc.getModuleCount() - 7) {
            } else {
                pattern_pos_map.set(`${row},${col}`, true);
            }
        }
    }

    const margin = 1;
    const module_count = qrc.getModuleCount() + (margin * 2);
    let remainder_modules = qrc.getModuleCount() % 3;
    let modules_to_cover = (qrc.getModuleCount() - remainder_modules) / 3;
    let left_padding = Math.ceil((module_count - modules_to_cover - (margin * 2)) / 2);
    // left_margin: 12
    // right_margin: 12
    // modules_to_cover: 11
    // http://localhost:3000/dev/qrcode_service?ssid=gordolio&psk=foobar&width=1000
    // 12
        let right_padding = Math.floor((module_count - modules_to_cover - (margin * 2)) / 2);
    // 12
    let min_module = left_padding; // 12
    let max_module = min_module + modules_to_cover;

    const isLogo = (row: number, col: number): boolean => {
        if (row >= min_module && col >= min_module) {
            if (row < max_module && col < max_module) {
                return true;
            }
        }
        return false;
    };

    let isDark = (row: number, col: number): boolean => {
        if (isLogo(row, col)) {
            return true;
        } else if (row - 1 < 0 || col - 1 < 0) {
            return false;
        } else if (row > qrc.getModuleCount() || col > qrc.getModuleCount()) {
            return false;
        }
        return qrc.isDark(row - 1, col - 1);
    };

    let isAlignmentPattern = (row: number, col: number): boolean => {
        let key = `${row - 1},${col - 1}`;
        if (pattern_pos_map.get(key)) {
            return true;
        }
        return false;
    };

    let isBullsEye = (row: number, col: number): boolean => {
        if ((row >= 3 && row <= 5) && (col >= 3 && col <= 5)) {
            return true;
        }
        if ((row <= qrc.getModuleCount() - 2
            && row >= qrc.getModuleCount() - 4)
            && (col >= 3 && col <= 5)) {
            return true;
        }
        if ((row >= 3 && row <= 5)
            && (col <= qrc.getModuleCount() - 2
                && col >= qrc.getModuleCount() - 4)) {
            return true;
        }
        return false;
    };

    const ctx = canvas.getContext('2d');
    if(!ctx) {
        console.error("Could not create 2d context");
        return null;
    }

    const tileW = options.width / module_count;
    const tileH = options.height / module_count;

        if(!/^#[\da-f]{6}00$/.test(options.background.hex())) {
    ctx.fillStyle = options.foreground.hex();
    ctx.fillRect(0, 0, options.width, options.height);
    ctx.fillStyle = options.background.hex();
    ctx.fillRect(Math.ceil(tileW * 0.2), Math.ceil(tileW * 0.2),
        Math.floor(options.width - (tileW * 0.4)), Math.floor(options.height - (tileW * 0.4)));
        }

    for (let row = 0; row < module_count; row++) {
        for (let col = 0; col < module_count; col++) {
            if (row === 0 || col === 0 ||
                row === module_count ||
                col === module_count) {
                ctx.fillStyle = options.background.hex();
            } else if (isDark(row, col) && !isLogo(row, col)) {
                if (isBullsEye(row, col)) {
                    ctx.fillStyle = options.bullsEye.hex();
                } else if (isAlignmentPattern(row, col)) {
                    ctx.fillStyle = options.alignmentPattern.hex();
                } else {
                    ctx.fillStyle = options.foreground.hex();
                }
                let w = (Math.floor((col + 1) * tileW) - Math.floor(col * tileW));
                let h = (Math.floor((row + 1) * tileH) - Math.floor(row * tileH));
                if (options.roundEdges) {
                    let rad_amount = Math.round(tileW * 0.5);
                    let small_amount = Math.round(tileW * 0.5);
                    let radObj = {
                        tl: rad_amount,
                        tr: small_amount,
                        br: rad_amount,
                        bl: small_amount
                    };
                    //above is dark or left is dark
                    if (isDark(row - 1, col) || isDark(row, col - 1)) {
                        radObj.tl = 0;
                    }
                    //right is dark or above is dark
                    if (isDark(row, col + 1) || isDark(row - 1, col)) {
                        radObj.tr = 0;
                    }
                    //left or below is dark
                    if (isDark(row, col - 1) || isDark(row + 1, col)) {
                        radObj.bl = 0;
                    }
                    //right or below is dark
                    if (isDark(row + 1, col) || isDark(row, col + 1)) {
                        radObj.br = 0;
                    }
                    //roundRect(ctx, Math.round(col * tileW), Math.round(row * tileH), w, h, 3, true, false);
                    roundRect(ctx, Math.floor(col * tileW), Math.floor(row * tileH), w, h, radObj, true, false);
                } else {
                    ctx.fillRect(Math.floor(col * tileW), Math.floor(row * tileH), w, h);
                }
            }
        }
    }

        const main_logo = logo;

    const logo_width = tileW * modules_to_cover;
        const scale = (logo_width / main_logo.width);
        const width = Math.ceil(main_logo.width * scale);
        const height = Math.ceil(main_logo.height * scale);
    //const width = Math.ceil(tileW * modules_to_cover);
    //const height = Math.ceil(tileW * modules_to_cover);
    const x = Math.floor(left_padding * tileW);
    const y = Math.floor((left_padding * tileW) + ((tileW * modules_to_cover) / 2) - height / 2);
    //const x = Math.floor((options.width - width) / 2);
    //const y = Math.floor((options.height - height) / 2);

    ctx.fillStyle = options.foreground.hex();
    ctx.fillRect(x, x, width, width);
        if(!/^#[\da-f]{6}00$/i.test(options.logoBackground.hex())) {
    ctx.fillStyle = options.logoBackground.hex();
    ctx.fillRect(Math.ceil(x + (tileW * 0.2)), Math.ceil(x + (tileW * 0.2)),
        Math.floor(width - (tileW * 0.4)), Math.floor(width - (tileW * 0.4)));
        }
        if(logo) {
            if(image_brand.startsWith("smartaira")) {
                ctx.drawImage(main_logo,
                    Math.ceil(x + (tileW * 1.1)),
                    Math.ceil(y + (tileW * 1.1)),
                    Math.floor(width - (tileW * 2.2)),
                    Math.floor(height - (tileW * 2.2))
                );
            } else {
                ctx.drawImage(main_logo, Math.ceil(x + (tileW * 0.4)), Math.ceil(y + (tileW * 0.4)),
        Math.floor(width - (tileW * 0.8)), Math.floor(height - (tileW * 0.8)));
            }
        }

    if (options.drawGrid) {
        for (let row = 0; row < module_count; row++) {
            for (let col = 0; col < module_count; col++) {
                ctx.beginPath();
                ctx.moveTo(Math.floor(col * tileW), Math.floor(row * tileW));
                ctx.lineTo(Math.floor((col + 1) * tileW), Math.floor(row * tileW));
                ctx.stroke();

                ctx.beginPath();
                ctx.moveTo(Math.floor(col * tileW), Math.floor(row * tileW));
                ctx.lineTo(Math.floor(col * tileW), Math.floor((row + 1) * tileW));
                ctx.stroke();
            }
        }
    }
};

export const QrCodeComponent = (props:QrCodeProperties) => {
    const canvas = createRef<HTMLCanvasElement>();

    const logo = useImage(SmartairaIcon);

    useEffect(()=>{
        if(logo.loaded) {
            draw(canvas.current, props, logo.image);
        }
    },[props, canvas, logo]);


    return (
        <canvas className={props.className} ref={canvas} width={props.width} height={props.height}/>
    );
};
